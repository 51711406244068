import React from 'react';
import { AmplifySignUp } from '@aws-amplify/ui-react'
import { SelectField } from '@aws-amplify/ui-react';

//Accountant
//Electrician
//Dentist
//Fencing
//General Contractor
//HVAC
//Home Cleaners
//Insurance Agent
//LandScape
//LockSmith
//Mortgage Broker
//Mover
//Painters
//Plumber
//Roofer
//Realtor


const SignUp = () => {
    return (
        <AmplifySignUp
            headerText='Recommended Sign up'
            slot="sign-up"
            usernameAlias="email"
            formFields={[
            {
                type: "custom:dba",
                label: "Business Name*",
                inputProps: { required: true },
                hint: "Your generally known business name or DBA"
            },
            {
                type: "custom:category",
                label: "Business category*",
                inputProps: { required: true },
                hint: "Choose: Accoutant, Electrician, Dentist, Fencing, General Contractor, HVAC, Home Cleaners, Insurance Agent, Landscaper, Locksmith, Mortgage Broker,"
                 + " Mover, Painter, Plumber, Roofer, Realtor"
            },
            {
                type: "given_name",
                label: "First Name*",
                inputProps: { required: true },
            },
            {
                type: "family_name",
                label: "Last Name*",
                inputProps: { required: true },
            },
            {
                type: "email",
                label: "Email*",
                inputProps: { required: true, autocomplete: "username" },
            },
            {
                type: "phone_number",
                label: "Phone*",
                inputProps: { required: true },
            },
            {
                type: "password",
                label: "Password*",
                inputProps: { required: true, autocomplete: "new-password" },
                hint: "Must include a letter, number and special character"
            },
            {
                type: "custom:zipcode",
                label: "Zip code*",
                inputProps: { required: true },
            },
            {
                type: "custom:street-address",
                label: "Street Address*",
                inputProps: { required: true },
            },
            {
                type: "custom:city",
                label: "City*",
                inputProps: { required: true },
            },
            {
                type: "custom:website",
                label: "Website",
                hint: "optional"
            },
        ]} 
      />
    )

}
// professional name
// dba
// zipcdoe
// address
// biz phone
// category - drop down
// email
// blurb
// website

export default SignUp;

// try {
//     const { user } = await Auth.signUp({
//         username,
//         password,
//         attributes: {
//             email,          // optional
//             phone_number,   // optional - E.164 number convention
//             // other custom attributes 
//         }
//     });
//     console.log(user);
// } catch (error) {
//     console.log('error signing up:', error);
// }