import React from 'react';
import handshake from '../images/handshake.jpg';
// import celebrate from '../images/celebrate.jpg';
import praise from '../images/praise.jpg';
import { Card, CardMedia, CardContent, Grid, CardActionArea } from '@material-ui/core';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Expo from './Expo'

const useStyles = makeStyles({
    root: {
      minWidth: 275,
      marginBottom: 30,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14, 
    },
    pos: {
      marginBottom: 12,
      paddingBottom: 30,
    },
    top: {
        display: 'flex', 
        flexDirection: 'row'
    }
  });

const DashBoard = () => {
    // const classes = useStyles();
    // const classes = useStyles();
    return (
      <Expo />
        // <Grid container justifyContent="center" alignItems="center" spacing={3}>
        //     <Grid item>
        //       <DashBoardCard url="/referrals" title="My Active Referrals" image={handshake} />
        //     </Grid>
        //     <Grid item>
        //       <DashBoardCard url="/recommendations" title="My Recommendations" image={praise} />
        //     </Grid>
        // </Grid>
    )
}

const DashBoardCard = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.root} variant="outlined">
          <CardActionArea component={Link} to={props.url}>
            <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="140"
                width="140"
                image={props.image}
                title="Contemplative Reptile"
            />
            <CardContent>
              {props.title}
            </CardContent>
          </CardActionArea>
         </Card>
       )
}



export default DashBoard;