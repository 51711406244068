import { API, graphqlOperation, Auth } from 'aws-amplify'
import { fetchBusinessCognito } from '../graphql/queries';
import { useAsync } from 'react-async-hook';
import Main from 'toolkit/layouts/Main';
import Card from "@mui/material/Card";
import TopHeader from "toolkit/components/TopHeader";
import { Typography } from '@material-ui/core';

const _fetchBusiness = async () => {
    const user = await Auth.currentUserInfo()
    const result = await API.graphql(graphqlOperation(fetchBusinessCognito, {userId: user.username }))
    return result
}

const LinkScreen = () => {
    console.log("render screen")
    const business = useAsync(_fetchBusiness);

    if(business.status === "loading") {
        return <h1>LOADING</h1>
    }

    console.log("bussssssss", business)

    const data = business.result.data.fetchBusinessCognito
    const bottomText = "Provide this link to your customers in order for them to write you a recommendation"
    
    return (
      <Main>
        <TopHeader topText={"Business Link"} bottomText={bottomText} boxSize={12}>
          <Typography>
            <p>{data.businessName}'s recommendation link:</p>
            <h3>http://www.gorecommended.com/add-recommendation/{data.busId}</h3>
          </Typography>
        </TopHeader>
      </Main>
    )

}

export default LinkScreen;