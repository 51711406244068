import React from 'react';
import './App.css';
import Amplify, {Auth} from 'aws-amplify';
import awsconfig from '../aws-exports';
import { withAuthenticator, AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import SignUp from '../auth/signUp';
import DashBoard from '../dashboard/dashboard';
import ReferralScreen from '../referrals/referralScreen';
import LinkScreen from '../link/linkScreen';
import RecommendationScreen from '../recommendations/recommendationScreen';
import Page from 'toolkit/components/Page'

Amplify.configure(awsconfig)

function App() {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
        setAuthState(nextAuthState);
        setUser(authData)
    });
}, []);

  return authState === AuthState.SignedIn && user ? (
    <Page>
      <Router>
        <Switch>
          <Route path ="/referrals">
            <ReferralScreen />
          </Route>
          <Route path ="/recommendations">
            <RecommendationScreen />
          </Route>
          <Route path ="/link">
            <LinkScreen />
          </Route>
          <Route path ="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </Page>
  ) : (
    <Page>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/*">
            <AmplifyAuthenticator> 
              <AmplifySignIn headerText="Recommended Business Account Sign In" slot="sign-in"/>
              <SignUp />
            </AmplifyAuthenticator> 
          </Route>
        </Switch>
      </Router>
    </Page>
  )
}

async function signOut() {
  try {
      await Auth.signOut();
  } catch (error) {
      console.log('error signing out: ', error);
  }
}

const Home = () => {
  return(
    <DashBoard />

      // <Grid container direction="row" justifyContent="center" alignItems="center">
      //   <Grid item xs={12} style={{textAlign: "center"}}>
      //     <h1>Recommended Business Dashboard</h1>
      //   </Grid>
      //   <Grid style={{marginTop: 70}}>
      //   </Grid>
      // </Grid>
  )
}

export default App;
