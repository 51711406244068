import { Auth } from 'aws-amplify'
import { useHistory } from "react-router-dom";

export const getCurrentUser = async () => {
  try{
      const user = await Auth.currentUserInfo(); 
      return user.username;
  } catch {
      return null
  }
}

export const capitalizeWord = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
 }
 
