// import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { myReferrals } from '../graphql/queries';
import { useAsync } from 'react-async-hook';
import Main from 'toolkit/layouts/Main';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TopHeader from "toolkit/components/TopHeader";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';


const getReferrals = async () => {
    console.log("called")
    const user = await Auth.currentUserInfo()
    console.log("got user", user)
    const result = await API.graphql(graphqlOperation(myReferrals, {userId: user.username }))
    return result
}

const ReferralScreen = () => {
    console.log("render screen")
    const referrals = useAsync(getReferrals);
    console.log("after referral")
    console.log("state user", referrals)

    if(referrals.status === "loading") {
        return <h1>LOADING</h1>
    }

    const data = referrals.result.data.myReferrals

    if(data.length === 0){
      return (
          <Main>
              <TopHeader topText="My Referrals" boxSize={7}>
                  <Grid container spacing={10} justifyContent = "center">
                      <Grid item>
                          <h2>You do not have any referrals</h2>
                      </Grid>
                  </Grid>
              </TopHeader>
          </Main>
      )
  }

    const refComponent = data.map((referral) => {
        return <ReferralCard referral={referral} />
    })

    
    return (
      <Main>
        <TopHeader topText={"test"} bottomText={"bottomText"} boxSize={5}>
          {refComponent}
        </TopHeader>
      </Main>

        // <Container>
        //     <Grid container alignItems="center" spacing={3} justify="center">
        //         {
        //             refComponent
        //         }
        //     </Grid>    
        // </Container>
    )

}

const ReferralCard = (referral) => {
  const showDivider = () => {
    if (!(referral.index === (referral.length - 1))) {
      return <Divider />
    }
  }
    return(
      <Card style={{ marginBottom: "30px"}}>
          <Box pl={4} pt={3}>
            <Typography style={{ fontWeight: "bold"}}>
              Prospect: {referral.referral.prospectFirstName} {referral.referral.prospectLastName}
            </Typography>
          </Box>
          <Box pl={4} pb={2}>
            <Typography style={{ fontStyle: "italic"}}>
              Phone: { referral.referral.prospectPhoneNumber}
            </Typography>
          </Box>
          <Box pl={4} pb={2}>
            <Typography style={{ fontStyle: "italic"}}>
              Email: {referral.referral.prospectEmail}
            </Typography>
          </Box>
          <Box pl={4} pb={2}>
            <Typography style={{ fontStyle: "italic"}}>
              Referred By: {referral.referral.referrerFirstName} {referral.referral.referrerLastName}
            </Typography>
          </Box>
          {showDivider()}
    </Card>
    )
}

export default ReferralScreen;
