import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';
import logo from 'images/logo.jpg';
import { Auth } from 'aws-amplify';
import { getCurrentUser } from 'helpers';
import { useAsync } from 'react-async-hook';
import { useHistory } from 'react-router-dom';

async function signOut() {
  try {
      await Auth.signOut();
      window.location.reload(false);
  } catch (error) {
  }
}

const checkUser =  async () => {
  const user = await getCurrentUser()
  if(user){
    return true
  } else {
    return false
  }
}


const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const history = useHistory();
  const { mode } = theme.palette;
  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;
  const userSignedIn = useAsync(checkUser);


  const renderSignIn = () => {
    if(userSignedIn.status === "loading"){
      return null;
    }

    if (!userSignedIn.result){
      return(
        <Box marginLeft={4}>{ /* Sign out */}
          <Button variant="contained" onClick={() => history.push('/signIn')} >Sign in</Button>
        </Box> 
      )
    } else {
      return (
        <Box marginLeft={4}>{ /* Sign out */}
          <Button variant="contained" onClick={() => signOut()} >Sign Out</Button>
        </Box>
      )
    }
  }

  const renderRecommendationLink = () => {
    if(userSignedIn.status === "loading"){
      return null;
    }

    if (!userSignedIn.result){
      return null
    } else {
      return (
        <Box>
          <Link
            underline="none"
            component="a"
            href="/link"
            color={'text.primary'}
          >
            My Recommendation Link
          </Link>
        </Box>
      )
    }
  }
  

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="theFront"
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={'img'}
          src={logo}
          sx={{ height: 45, width: 240 }}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        {renderRecommendationLink()}
        <Box marginLeft={4}>
          <Link
            underline="none"
            component="a"
            href="/referrals"
            color={'text.primary'}
          >
            My Active Referrals
          </Link>
        </Box>
        <Box marginLeft={4}>
          <Link
            underline="none"
            component="a"
            href="/recommendations"
            color={'text.primary'}
          >
            My Recommendations
          </Link>
        </Box>
        {renderSignIn()}
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
