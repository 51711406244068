import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Container from 'toolkit/components/Container';

const TopHeader = ({ children, topText, bottomText, boxSize }) => {
  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const theme = useTheme();

  let md = 9
  if(boxSize){
    md = boxSize
  }

  return (
    <Box>
      <Box bgcolor={'primary.main'} paddingY={4}>
        <Container>
          <Typography
            variant="h4"
            fontWeight={700}
            gutterBottom
            sx={{ color: 'common.white' }}
          >
            {topText}
          </Typography>
          <Typography variant="h6" sx={{ color: 'common.white' }}>
            {bottomText}
          </Typography>
        </Container>
      </Box>
      <Container paddingTop={'0 !important'} marginTop={-8}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Card sx={{ boxShadow: 3 }}>
             
            </Card>
          </Grid>
          <Grid item xs={12} md={md}>
            <Card sx={{ boxShadow: 3, padding: 4 }}>
              {children}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

TopHeader.propTypes = {
  children: PropTypes.node,
};

export default TopHeader;
