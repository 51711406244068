import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Main from 'toolkit/layouts/Main';
import Container from 'toolkit/components/Container';
import {
  Advantages,
  Hero,
  Work,
} from './components';

const Expo = () => {
  return (
    <Main colorInvert={true}>
      <Hero />
      <Divider />
      <Container>
        <Advantages />
      </Container>
      <Divider />
      <Container>
        <Work />
      </Container>
    </Main>
  );
};

export default Expo;
