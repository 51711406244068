import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import recIllustration from 'images/recommendIllustration.png'
import closeIllustration from 'images/closeIllustration.png'
import neighborIllustration from 'images/neighborIllustration.png'

const mock = [
  {
    title: 'Get recommendations from your clients',
    description:
      'You can\'t get referrals without recommendations from your current customers.  Email your business link to customers ',
    illustration: recIllustration,
  },
  {
    title: 'Neighbors and friends will see your business',
    description:
      'When someone in the neighborhood or apart of your customer\'s friend group is looking for a service.  They will see your recommendationa and can request to be contacted',
    illustration: neighborIllustration,
  },
  {
    label: 'Client portal access',
    title: 'High close rate leads',
    description:
      'Prospects can only request info from two providers and the lead is referred directly from one of your customers.  Pricing is per lead and your first lead is free!',
    illustration: closeIllustration
  },
];

const Work = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          How it works
        </Typography>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} align={'center'}>
          Your client's recommendations are instantly seen by people in their neighborhood
          and various groups they may belong to
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          align={'center'}
        >
          Create an account and send your business link to your clients.  They can use the link
          to write a recommendation for you
        </Typography>
      </Box>  
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid
            data-aos="fade-up"
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
            key={i}
            item
            container
            xs={12}
            spacing={4}
            direction={i % 2 === 1 ? 'row-reverse' : 'row'}
          >
            <Grid item container alignItems={'center'} xs={12} sm={6}>
              <Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 700 }}
                >
                  {item.title}
                </Typography>
                <Typography color="text.secondary">
                  {item.description}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              container
              justifyContent={'center'}
              alignItems={'center'}
              xs={12}
              sm={6}
            >
              <Box
                component={'img'}
                src={item.illustration}
                alt={item.title}
                width={1}
                maxWidth={'80%'}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Work;
