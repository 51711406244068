/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const myReferrals = /* GraphQL */ `
  query MyReferrals($userId: String!) {
    myReferrals(userId: $userId) {
      prospectFirstName
      prospectLastName
      prospectEmail
      prospectPhoneNumber
      referrerFirstName
      referrerLastName
      referrerEmail
    }
  }
`;
export const myRecommendations = /* GraphQL */ `
  query MyRecommendations($userId: String!) {
    myRecommendations(userId: $userId) {
      firstName
      lastName
      statement
    }
  }
`;
export const fetchBusinessCognito = /* GraphQL */ `
  query FetchBusinessCognito($userId: String!) {
    fetchBusinessCognito(userId: $userId) {
      businessName
      busId
    }
  }
`;
