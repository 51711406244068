import Main from 'toolkit/layouts/Main';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import TopHeader from "toolkit/components/TopHeader";
import Grid from '@mui/material/Grid';
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { useAsync } from 'react-async-hook';
import { myRecommendations } from '../graphql/queries';

const fetchRecommendations = async () => {
    console.log("called")
    const user = await Auth.currentUserInfo()
    console.log("got user", user)
    const result = await API.graphql(graphqlOperation(myRecommendations, {userId: user.username }))
    return result
}

const RecommendationScreen = () => {
    const recommendations = useAsync(fetchRecommendations);

    if(recommendations.status === "loading") {
        return <h1>LOADING</h1>
    } 
    console.log("returned recommendations", recommendations)

    const data = recommendations.result.data.myRecommendations;

    if(data.length === 0){
        return (
            <Main>
                <TopHeader topText="My Recommendations" boxSize={7}>
                    <Grid container spacing={10} justifyContent = "center">
                        <Grid item>
                            <h2>You do not have any recommendations</h2>
                        </Grid>
                    </Grid>
                </TopHeader>
            </Main>
        )
    }

    const recommendationComp = data.map((recommendation) => {
        return <RecommendationCard recommendation={recommendation} />
    })

    return(
      <Main>
        <TopHeader topText={"test"} bottomText={"bottomText"} boxSize={5}>
          {recommendationComp}
        </TopHeader>
      </Main>
    )

}

//TODO: add date given to recommendation.  going to have to transform it from something
const RecommendationCard = (recommendation) => {
    console.log(recommendation)
    return(
      <Card style={{ marginBottom: "30px"}}>
        <CardContent>
            <Box pl={4} pt={3}>
              <Typography style={{ fontWeight: "bold"}}>
                <h2>{recommendation.recommendation.firstName} {recommendation.recommendation.lastName}</h2>
              </Typography>
            </Box>
            <Box pl={4} pt={3}>
              <Typography style={{ fontWeight: "bold"}}>
                <h4>the question</h4>
              </Typography>
              <Typography>
                question answer
              </Typography>
            </Box>
            <Box pl={4} pt={3}>
              <Typography style={{ fontWeight: "bold"}}>
                <h4>the question</h4>
              </Typography>
              <Typography>
                question answer
              </Typography>
            </Box>
            <Box pl={4} pt={3}>
              <Typography style={{ fontWeight: "bold"}}>
                <h4>the question</h4>
              </Typography>
              <Typography>
                question answer
              </Typography>
            </Box>
            <Box pl={4} pt={3}>
              <Typography style={{ fontWeight: "bold"}}>
                <h4>the question</h4>
              </Typography>
              <Typography>
                question answer
              </Typography>
            </Box>
        </CardContent>
      </Card>

    )

}

export default RecommendationScreen;